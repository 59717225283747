<template>
  <div class="Teacher-checkExam">
    <div class="stu-module-header">
      <div class="stu-module-title">在线考试批阅</div>
    </div>
    <div class="content">
      <el-form :model="form" inline size="medium">
        <el-form-item>
          <el-input v-model="form.teaNameOrCode" placeholder="教师姓名/工号">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="init"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="考试批次">
          <el-select v-model="form.batchId" @change="init" clearable>
            <el-option
              v-for="(item, index) in bacthOptions"
              :key="index"
              :label="item.batchName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="450"
        ref="tableRef"
        v-loading="loading"
        @selection-change="selectionChange"
        stripe
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columns"
          :prop="item.prop"
          :key="index"
          :label="item.label"
          align="center"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="操作"
          :width="60"
          align="center"
          v-if="checkRoot"
        >
          <template slot-scope="scope">
            <el-button @click="onChecked(scope.row)" type="text"
              >批阅</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { QueryEduCourseExamReview } from "@/libs/api/teacher/check";
import { EduExamBatch } from "@/libs/api/teacher/select";
export default {
  name: "teacher-checkExam",
  data() {
    return {
      form: {
        teaNameOrCode: null,
        batchId: "",
      },
      loading: false,
      bacthOptions: [],
      tableData: [],
      columns: [
        { prop: "examName", label: "考试名称", width: 100 },
        { prop: "courseCode", label: "课程编号" },
        { prop: "courseName", label: "课程名称", width: 120 },
        { prop: "answerStartTime", label: "作答开始时间", width: 130 },
        { prop: "answerEndTime", label: "作答结束时间", width: 130 },
        { prop: "totalCount", label: "考试总条数", width: 90 },
        { prop: "reviewCount", label: "已批阅数" },
        { prop: "nsumCon", label: "待批阅数" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
      selectionData: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("eduTeacherOnExam:list");
    },
    checkRoot() {
      return this.$sysPerKey("eduTeacherOnExam:check");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.selectInit();
        this.init();
      }
    },
  },
  mounted() {
    if (this.queryRoot) {
      this.selectInit();
      this.init();
    }
  },
  methods: {
    selectInit() {
      const siteId = this.user.siteId;
      EduExamBatch({ siteId }).then((res) => {
        this.bacthOptions = res.data;
      });
    },
    init() {
      this.loading = true;
      QueryEduCourseExamReview({
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChecked(row) {
      if (row) {
        this.$router.push({
          path: "checkExam/list",
          query: {
            workSetId: row.id,
            courseId: row.courseId,
          },
        });
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    selectionChange(val) {
      this.selectionData = val;
    },
  },
};
</script>

<style scope lang="less">
.Teacher-checkExam {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
  }
}
</style>
